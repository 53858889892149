//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get, call } from "vuex-pathify";
import ViHeader from "~/components/ViHeader";
import ViFooter from "~/components/ViFooter";
import capitalize from "lodash/capitalize";

export default {
  name: "ViIndexPage",
  components: {
    ViHeader,
    ViFooter,
  },
  data() {
    return {
      selectedCategoryId: null,
      selectedSubCategoryId: null,
      searchQuery: "",
      accessibilityLevels: {
        full_accessible: true,
        partial_accessible: true,
        not_accessible: true,
      },
      results: [],
      viPopupExample: true,
    };
  },
  async fetch() {
    if (!this.selectedDisabilitiesCategory) {
      this.selectDisabilitiesCategory("justView");
    }
    this.selectedCategoryId = this.$route.query.categoryId || null;
    this.selectedSubCategoryId = this.$route.query.subCategoryId || null;
    await this.search();
  },
  methods: {
    selectCity: call("settings/select"),
    selectDisabilitiesCategory: call(
      "disabilitiesCategorySettings/selectCategory"
    ),
    async search() {
      this.results = await this.$axios.$get("/api/objects/filter", {
        params:
          this.selectedCity.id > 1
            ? {
                query: this.searchQuery,
                cityId: this.selectedCity.id,
                disabilitiesCategory: this.selectedDisabilitiesCategory
                  .category,
                accessibilityLevels: Object.keys(
                  this.accessibilityLevels
                ).filter((key) => this.accessibilityLevels[key] === true),
                subCategoryId: this.selectedSubCategoryId,
              }
            : {
                query: this.searchQuery,
                disabilitiesCategory: this.selectedDisabilitiesCategory
                  .category,
                accessibilityLevels: Object.keys(
                  this.accessibilityLevels
                ).filter((key) => this.accessibilityLevels[key] === true),
                subCategoryId: this.selectedSubCategoryId,
              },
      });
    },
  },
  filters: {
    capitalize(val) {
      return capitalize(val);
    },
  },
  computed: {
    disabilitiesCategorySettings: get(
      "disabilitiesCategorySettings/categories"
    ),
    selectedDisabilitiesCategory: get(
      "disabilitiesCategorySettings/currentCategory"
    ),
    cities: get("cities/list"),
    objectCategories: get("objectCategories/categories"),
    selectedCity: get("cities/selectedCity"),
    visualImpairedModeSettings: get("visualImpairedModeSettings"),
    viSettingsClasses() {
      return {
        "--noto": this.visualImpairedModeSettings.fontFamily === "Noto",
        "--black": this.visualImpairedModeSettings.colorTheme === "black",
        "--sm": this.visualImpairedModeSettings.fontSize === "sm",
        "--md": this.visualImpairedModeSettings.fontSize === "md",
        "--lrg": this.visualImpairedModeSettings.fontSize === "lrg",
      };
    },
    subCategories() {
      const category = this.objectCategories.find(
        (category) => category.id == this.selectedCategoryId
      );
      if (!category) {
        return [];
      }
      return category.subCategories;
    },
  },
};
