//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { call, get } from "vuex-pathify";
import Username from "~/components/Username";

export default {
  components: { Username },
  methods: {
    disableVisualImpairedMode: call("visualImpairedModeSettings/disable"),
    ...call("visualImpairedModeSettings", [
      "changeFontFamily",
      "changeFontSize",
      "changeColorTheme",
    ]),
  },
  computed: {
    name: get("authentication/name"),
    user: get("authentication/user"),
    visualImpairedModeSettings: get("visualImpairedModeSettings"),
  },
};
