import { render, staticRenderFns } from "./ViHeader.vue?vue&type=template&id=6f459d38&"
import script from "./ViHeader.vue?vue&type=script&lang=js&"
export * from "./ViHeader.vue?vue&type=script&lang=js&"
import style0 from "./ViHeader.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Username: require('/frontend/src/components/Username.vue').default})
